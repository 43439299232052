<template>
  <div class="content-main flex-column">
    <div class="head">
      <div class="head-title">
        <h3 class="title">生成结果</h3>
      </div>
      <div class="sub-title sub-title_style">
        <span>结果预览</span>
      </div>
    </div>
    <div class="content flex flex1">
      <div class="left-view flex1">
        <div class="result-box center-center">
          <div class="result-content center-center"
               v-loading="loading"
               :element-loading-svg="loadingIcon"
               element-loading-background="rgba(5, 2, 11, 0.6)"
          >
            <div v-if="!resultData.outPutUrl" class="result-text">
              <img class="r-img" src="../assets/images/img-page.png" alt="">
              <div class="d-text">AI创作</div>
              <div class="sub-text">快去左侧输入你的灵感创意吧～</div>
            </div>
            <!-- <div class="">{{ resultData.outPutUrl }}</div> -->
            <img v-else class="result-img" :src="resultData.outPutUrl" alt="">
          </div>
        </div>
      </div>
      <div class="right-bar">
        <Operation
            @onOpertion="onOpertion"
        />
      </div>
    </div>
    <div class="bottom-content">
      <div class="sub-title sub-title_style">
        <span>历史数据</span>
      </div>
      <div class="history-box">
        <div v-if="taskList.list.length > 0" class="history-list flex">
          <!-- <div class="page-icon left-icon center-center">
            <el-icon :size="size" color="#DCDCDC">
              <ArrowLeft />
            </el-icon>
          </div> -->
          <div class="history-img_comtent flex flex1">
            <el-col :span="4" v-for="item in taskList.list" :key="item.id">
              <div class="history-img_item" @click="getTaskDetail(item)">
                <div class="img-area" :style="{'background-image':`url(${item.outPutUrl}`}"></div>
                <div class="creat-time">{{ item.createdAt }}</div>
              </div>
            </el-col>
          </div>
          <!-- <div class="page-icon right-icon center-center">
            <el-icon :size="size" color="#DCDCDC">
              <ArrowRight />
            </el-icon>
          </div> -->
        </div>
        <div v-if="taskList.list.length > 0" class="bottom-page center-center">
          <el-pagination
              layout="prev, pager, next"
              :total="totalNum"
              :page-size="6"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <el-dialog
        v-model="dialogVisible"
        width="65%"
    >
      <div class="result-img center-center">
        <img class="result-img" :src="resultData.enhanceOutputUrl" alt="">
      </div>
    </el-dialog>
  </div>
</template>
<script setup>
import {ref, onMounted, reactive, watch} from 'vue'
import {useStore} from 'vuex'
import {postCreatTask, postCollectTask, postTaskList} from '@/network/api/user'
import emitter from '@/utils/bus'
import Operation from './OperationPage'
import {ElMessage} from 'element-plus'
import {saveAs} from 'file-saver'
import {getToken} from '@/utils/auth'

const loadingIcon = '<svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728=""><path fill="#999" d="M512 64a32 32 0 0 1 32 32v192a32 32 0 0 1-64 0V96a32 32 0 0 1 32-32zm0 640a32 32 0 0 1 32 32v192a32 32 0 1 1-64 0V736a32 32 0 0 1 32-32zm448-192a32 32 0 0 1-32 32H736a32 32 0 1 1 0-64h192a32 32 0 0 1 32 32zm-640 0a32 32 0 0 1-32 32H96a32 32 0 0 1 0-64h192a32 32 0 0 1 32 32zM195.2 195.2a32 32 0 0 1 45.248 0L376.32 331.008a32 32 0 0 1-45.248 45.248L195.2 240.448a32 32 0 0 1 0-45.248zm452.544 452.544a32 32 0 0 1 45.248 0L828.8 783.552a32 32 0 0 1-45.248 45.248L647.744 692.992a32 32 0 0 1 0-45.248zM828.8 195.264a32 32 0 0 1 0 45.184L692.992 376.32a32 32 0 0 1-45.248-45.248l135.808-135.808a32 32 0 0 1 45.248 0zm-452.544 452.48a32 32 0 0 1 0 45.248L240.448 828.8a32 32 0 0 1-45.248-45.248l135.808-135.808a32 32 0 0 1 45.248 0z"></path></svg>'
const props = defineProps({
  taskDetail: Object,
  taskList: Object,
  totalNum: Number
})
const emit = defineEmits(['taskDetail', 'getTaskList'])

const {dispatch} = useStore()

let isShowResult = ref(false)
let resultData = reactive({})
let queryParams = reactive({
  pageIndex: 1,
  pageSize: 6
})
// let taskList = reactive({
//   list: []
// })
// let totalNum = ref(0)

function sleepFun(logStr) {
  return new Promise((res) => {
    return setTimeout(() => {
      res(logStr)
    }, 1000)
  })
}

// const getTaskList = async () => {

//   for (let i = 0; i < 30; i++) {
//     const token = sessionStorage.getItem('token')
//     // const token = getToken()
//     if (token && token.length > 0) {
//       break
//     }

//     if (i === 29) {
//       return
//     }
//     await sleepFun(i + 1)
//   }


//   const res = await postTaskList(queryParams)
//   if (res && res.code === 0) {
//     const {list, total} = res.data
//     taskList.list = list
//     totalNum.value = total
//     queryParams.pageIndex = 1
//   }
// }
// 获取任务详情
const getTaskDetail = (item) => {
  emit('getTaskDetail', {id: item.id})
}
// 刷新
let loading = ref(false)
const refreshImg = async () => {
  loading.value = true
  // eslint-disable-next-line no-unused-vars
  let {id, ...param} = resultData
  const res = await postCreatTask(param)
  if (res && res.code === 0) {
    resultData = res.data
    emit('getTaskList')
    ElMessage.success(res.message)
    loading.value = false
    dispatch('user/getInfo')
  }
}
// 下载
const downloadImg = () => {
  saveAs('resultData.outPutUrl')
  // getImgArrayBuffer(resultData.outPutUrl)
}
const getImgArrayBuffer = (url, idx) => {
  console.log(url)
  // var that = this
  return new Promise((resolve, reject) => {
    //通过请求获取文件blob格式
    let xmlhttp = new XMLHttpRequest();
    xmlhttp.onprogress = function (event) {
      if (event.lengthComputable) {
        var loaded = parseInt(event.loaded / event.total * 100) + "%";
        if (loaded == '100%') {
          // that.tableList[idx].downNum++
        }
      }
    }
    xmlhttp.open("GET", url, true);
    xmlhttp.responseType = "blob";
    xmlhttp.onload = function () {
      if (this.status == 200) {
        resolve(this.response);
      } else {
        reject(this.status);
      }
    };
    xmlhttp.send();
  });
}
let dialogVisible = ref(false)
// 清晰化
const viewBigImg = () => {
  dialogVisible.value = true
}
// 收藏
const collectImg = async () => {
  const res = await postCollectTask({id: resultData.id, collected: 2})
  if (res && res.code === 0) {
    ElMessage.success(res.message)
  }
}
const onOpertion = (typeName) => {
  const {type} = typeName
  if (!resultData?.outPutUrl) {
    return
  }
  switch (type) {
    case 'refresh':
      refreshImg()
      break;
    case 'download':
      downloadImg()
      break;
    case 'view':
      viewBigImg()
      break
    case 'collect':
      collectImg()
      break
    default:
  }
}
const handleSizeChange = (pageNum) => {
  queryParams.pageIndex = pageNum
  // getTaskList()
  emit('getTaskList', pageNum)
}
const handleCurrentChange = (pageNum) => {
  queryParams.pageIndex = pageNum
  // getTaskList()
  emit('getTaskList', pageNum)
}
watch(props.taskDetail, (n, o) => {
  // resultData = n.task
  // console.log(resultData)
})
onMounted(() => {
  emitter.on("getDaskDetail", (val) => {
    resultData = val
    // console.log(resultData)
    // console.log(val, resultData)
    queryParams.pageIndex = 1
    emit('getTaskList', queryParams.pageIndex)
  })
  if (JSON.stringify(props.taskDetail.task) != '{}') {
    // isShowResult.value = true
    queryParams.pageIndex = 1
    // getTaskList()
    emit('getTaskList', queryParams.pageIndex)
    resultData = props.taskDetail.task
  }
  // const token = getToken()
  // const token = sessionStorage.getItem('token')
  // if (token) {
  //   getTaskList()
  // } else {
  //   setTimeout(() => {
  //     getTaskList()
  //   }, 1500)
  // }
})

</script>
<style lang="scss" scoped>
.content-main {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-left: 24px;

  .content {
    width: 100%;
    background-color: var(--page-bg-color);
    margin-top: 14px;
    margin-bottom: 14px;
    overflow: hidden;
  }
}

.result-box {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .result-content {
    height: 100%;
    text-align: center;

    .r-img {
      width: 120px;
      height: 129px;
    }

    .d-text {
      font-size: 24px;
      color: var(--page-textsub-color);
      margin-top: 35px;
      margin-bottom: 16px;
    }

    .sub-text {
      font-size: 18px;
      color: var(--page-textsub-color);
    }
  }
}

.result-img {
  max-width: 100%;
  max-height: 100%;
}

.right-bar {
  width: 78px;
  height: 100%;
  background-color: var(--page-subbg-color);
  overflow-y: auto;
}

.bottom-content {
  width: 100%;

  .history-box {
    height: 240px;
    width: 100%;
    background-color: var(--page-bg-color);
    border-radius: 12px;
    margin-top: 14px;
    padding: 24px 10px 28px 10px;
  }

  .img-area {
    width: 116px;
    height: 116px;
    background: url(../assets/images/img-page.png) no-repeat;
    background-size: cover;
  }

  .creat-time {
    font-size: 14px;
    color: var(--page-textsub-color);
    padding-top: 6px;
  }
}

.bottom-page {
  margin-top: 15px;
}

.el-col-4 {
  max-width: 100%;
  flex: 0 0 16.6666666667%;
}
</style>
