<template>
  <div class="container flex-column">
    <div class="head-title">
      <h3 class="title">作品集</h3>
    </div>
    <div class="task-main flex1">
      <el-scrollbar height="100%">
        <div class="flxe-wrap">
          <div v-if="taskList.list.length > 0" class="task-item" v-for="(item, index) in taskList.list" :key="item.id">
            <div class="img-item center-center" @mouseover="onMouseover(item, index)" @mouseleave="onMouseleave(item, index)">
              <img class="imgs" :src="item.outPutUrl" alt="">
              <div v-if="item.isShow" class="item-operation">
                <div class="operation center-center">
                  <el-icon class="right-icon">
                    <Edit :color="iconColor" @click="getTaskDetail(item)" />
                  </el-icon>
                  <el-icon><Delete :color="iconColor" @click="onRemoveTask(item)" /></el-icon>
                </div>
              </div>
            </div>
            <div class="img-time">{{ item.createdAt }}</div>
          </div>
          <div v-else class="no-data">暂无数据</div>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted } from 'vue'
import { postTaskList, postRemoveTask } from '@/network/api/user'
import { ElMessage } from 'element-plus'
import { getToken } from '@/utils/auth'
const iconColor = ref('#1f1f20')
let queryParams = reactive({
  collectType: 2,
  pageIndex: 1,
  pageSize: 500
})
let taskList = reactive({
  list: []
})
let totalNum = ref(0)
const getTaskList = async () => {
  const res = await postTaskList(queryParams)
  if (res && res.code === 0) {
    const {list, total} = res.data
    taskList.list = list
    totalNum.value = total
  }
}
const onMouseover = (item, index) => {
  item.isShow = true
}
const onMouseleave = (item, index) => {
  item.isShow = false
}
const onRemoveTask = async (item) => {
  const res = await postRemoveTask({ id: item.id })
  if (res.code === 0) {
    ElMessage.success(res.message)
    getTaskList()
  }
}
const emit = defineEmits(['getTaskDetail'])
const getTaskDetail = (item) => {
  emit('onGetTaskDetail', {id: item.id})
}
onMounted(() => {
  // const token = getToken()
  const token = sessionStorage.getItem('token')
  if (token) {
    getTaskList()
  }
})
</script>
<style lang="scss" scoped>
.container {
  padding: 0 0 24px 30px;
}
.no-data {
  width: 100%;
  text-align: center;
  font-size: 18px;
  color: #AFB1B6;
  text-align: center;
}
.task-main {
  width: 100%;
  height: calc(100% - 60px);
}
.flxe-wrap {
  display: flex;
  flex-wrap: wrap;
}
.task-item {
  width: 256px;
  margin-right: 15px;
  margin-bottom: 20px;
  .img-item {
    width: 256px;
    height: 256px;
    box-sizing: border-box;
    background-color: #1E2022;
    border-radius: 12px;
    position: relative;
    overflow: hidden;
    .item-operation {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
      width: 100%;
      height: 100%;
      border-radius: 12px;
      background: rgba(30, 32, 34, 0.65);
    }
    .operation {
      background-color: #AFB1B6;
      height: 30px;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      .right-icon {
        margin-right: 10px;
      }
    }
    .imgs {
      width: 100%;
      height: auto;
    }
  }
  .img-time {
    text-align: center;
    margin-top: 12px;
    color: #DCDCDC;
    font-size: 14px;

  }
}
</style>
