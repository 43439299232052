import request from './request'
const BaseUrl = `${process.env.VUE_APP_BASE_API}`

// GET请求
export function GET(url = '', payload = {}) {
  // Java后端接收参数为强类型 避免null undefind 和空值
  const params = {}
  Object.keys(payload).forEach(key => {
    if (payload[key]) params[key] = payload[key]
  })
  return request.get(BaseUrl + url, { params })
}
// POST 请求
export function POST(url = '', payload = {}) {
  const FORMDATE = new FormData()
  Object.keys(payload).forEach(key => FORMDATE.append(key, payload[key]))
  return request.post(BaseUrl + url, FORMDATE)
}

// POST 请求
export function POST_JSON(url = '', payload = {}) {
  return request.post(BaseUrl + url, payload)
}

// POST URL 请求
export function POST_URL(url = '', payload = {}) {
  const FORMDATE = new FormData()
  Object.keys(payload).forEach(key => FORMDATE.append(key, payload[key]))
  return request.post(url, FORMDATE)
}

// POST FILW 请求
export function POST_FILE(url = '', payload = {}) {
  const FORMDATE = new FormData()
  Object.keys(payload).forEach(key => FORMDATE.append(key, payload[key]))
  return request({
    method: 'post',
    url: BaseUrl + url,
    data: FORMDATE,
    responseType: 'blob'
  })
}
