import axios from 'axios'
import store from '@/store'
import { getToken } from '@/utils/auth'
import { ElMessage } from 'element-plus'

// 创建请求
const service = axios.create({
  baseURL: process.env.VUE_APP_API, // .env文件配置接口地址
  timeout: 120 * 1000
})
// 请求前
service.interceptors.request.use(
  (config) => {
    // 默认参数
    let defalutParams = {
    }
    // 默认请求头
    const headers = {
      token: sessionStorage.getItem('token'),
      md5: sessionStorage.getItem('md5'),
      'Content-Type': 'application/json'
    }
    config.params = Object.assign(defalutParams, config.params || {})
    config.headers = store.getters.token ? Object.assign(headers, config.headers || {}) : headers
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
// 请求结果
service.interceptors.response.use(
  (response) => {
    //返回数据
    const res = response.data
    // console.log(res)
    if (res.code === 0) {
      return res
    } else {
      ElMessage.error(res)
      return Promise.reject(error)
    }
  },
  (error) => {
    msag(error)
    return Promise.reject(error)
  }
)
function msag (error) {
  if (error && error.response) {
    switch (error.response.status) {
      case 400:
        ElMessage({
          message: error.response.data.message,
          type: 'error',
          duration: 3 * 1000
        })
        break
      case 401:
        store.dispatch('Logout').then(() => {
          ElMessage({
            message: '用户登录已过期,请重新登录',
            type: 'error',
            duration: 1 * 1000
          })
          // router.push('/')
        })
        break
      case 403:
        ElMessage.error('拒绝访问')
        break

      case 404:
        ElMessage.error('请求地址出错')
        break

      case 408:
        ElMessage.error('请求超时')
        break

      case 500:
        ElMessage({
          message: '服务器内部错误',
          type: 'error',
          duration: 1 * 1000
        })
        break

      case 501:
        ElMessage.error('服务未实现')
        break

      case 502:
        ElMessage.error('网关错误')
        break

      case 503:
        ElMessage.error('服务不可用')
        break

      case 504:
        ElMessage.error('网关超时')
        break
      case 505:
        ElMessage.error('HTTP版本不受支持')
        break
      default:
    }
  }
}
export default service
