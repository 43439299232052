/**
 * @description demo
 */
// 状态树
import { postInfo } from '@/network/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
const state = () => ({
  token: getToken() || '',
  quota: 0
})
// 获取
const getters = {
  token: state => state.token
  // permissions: state => state.permissions
}
// 修改
const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_QUOTA: (state, quota) => {
    state.quota = quota
  }
  // SET_PERMISSIONS: (state, permissions) => {
  //   state.permissions = permissions
  // },
  // SET_MENU: (state, menu) => {
  //   state.menu = menu
  // }
}
const actions = {
  getInfo ({ commit }, data) {
    return new Promise(async(resolve, reject) => {
      const res = await postInfo(data)
      // console.log(res)
      if (res && res.code === 0) {
        commit('SET_QUOTA', res.data.freeBalance)
        resolve(res)
      } else {
        reject()
      }
    })
  }
}
// 异步修改
// const actions = {
//   login ({ commit }, data) {
//     // eslint-disable-next-line no-async-promise-executor
//     return new Promise(async(resolve, reject) => {
//       const res = await postLoginApi(data)
//       if (res.Error.Code === 200) {
//         const data = res.Data.Operator
//         commit('SET_TOKEN', data.Token)
//         commit('SET_PERMISSIONS', data.Permissions)
//         commit('SET_MENU', data.Menu)
//         sessionStorage.setItem('permissions', JSON.stringify(data.Permissions))
//         setToken(data.Token)
//         resolve(res)
//       } else {
//         reject()
//       }
//     })
//   }
// }

// export default createStore({

// })

export default {
  state,
  getters,
  mutations,
  actions,
}