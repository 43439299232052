import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import NProgress from 'nprogress'
import  'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'
import {  ROUTING_MODE, BASE_URL } from '../config'

// import Layout from '@/layout'

// import Login from '../views/LoginPage.vue'
import HomeView from '../views/HomeView.vue'

const routes = [
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: Login
  // },
  {
    path: '/',
    // component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        component: HomeView,
        name: 'Home',
        meta: { title: 'home', icon: 'home', affix: true }
      }
    ]
  }
]

const router = createRouter({
  history: ROUTING_MODE === 'HASH'
  ? createWebHistory(BASE_URL)
  : createWebHashHistory(BASE_URL),
  routes
})
/**
 * 安装路由
 * @param {*} app
 * @returns
 */
export function installRouter(app) {
  app.use(router)
  guard(router)
  return router
}
/**
 * 守卫
 * @param {*} router
 */
function guard(router) {
  router.beforeEach(async (to, from, next) => {
    // 跳转之前
    const hasToken = getToken()
    NProgress.start()
    if (hasToken) {
      // if (to.path === '/login') {
      //   next({ path: '/' })
      // } else {
      //   next()
      // }
      next()
    } else {
      // if (to.path !== '/login') {
      //   next({ path: '/login' })
      // } else {
      //   next()
      // }
      next()
    }
  })
  router.afterEach((to) => {
    // 跳转之后
    // console.log(to)
    NProgress.done()
  })
}
export default router
