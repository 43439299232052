<template>
  <section
      class="container"
      v-loading="loginLoading"
      :element-loading-svg="loadingIcon"
      element-loading-background="rgba(5, 2, 11, 0.6)"
  >
    <section class="main flex">
      <nav class="siderbar">
        <div class="nav-item center-center" :class="{'activeBg' : pageType === 'setting'}"
             @click="onChangeNav('setting')">
          <div class="icon icon1"></div>
        </div>
        <div class="nav-item center-center" :class="{'activeBg' : pageType === 'viewImgList'}"
             @click="onChangeNav('viewImgList')">
          <div class="icon icon2"></div>
        </div>
      </nav>
      <div v-if="pageType === 'setting'" class="content-page flex flex1">
        <div class="content">
          <StyleSetting :task-detail="taskDetail"/>
        </div>
        <div class="right-content flex1">
          <ResultPage
            :task-detail="taskDetail"
            :task-list="taskList"
            :total-num="totalNum"
            @getTaskList="(page) => getList(page)"
            @getTaskDetail="getTaskDetail"
          />
        </div>
      </div>
      <div v-if="pageType == 'viewImgList'" class="content-page flex felx1">
        <TaskList @onGetTaskDetail="getTaskDetail"/>
      </div>
    </section>
  </section>
</template>

<script setup>
import {reactive, ref, onMounted} from 'vue'
import {useRoute} from 'vue-router'
import { useStore } from 'vuex'
// import { useStore } from 'vuex'
import StyleSetting from '@/components/StyleSetting'
import ResultPage from '@/components/ResultPage'
import TaskList from '@/components/TaskList'
import {postTaskDetail, postLogin, postTaskList} from '@/network/api/user'
// import {setToken, getToken, removeToken} from '@/utils/auth'
import emitter from '@/utils/bus'

const router = useRoute()

let {state, dispatch} = useStore()

let pageType = ref('setting')

// const store = useStore

const onChangeNav = (val) => {
  pageType.value = val
}
let taskDetail = reactive({
  task: {}
})
const getTaskDetail = async (id) => {
  const res = await postTaskDetail({...id})
  if (res && res.code === 0) {
    onChangeNav('setting')
    emitter.emit('getDaskDetail', res.data)
    taskDetail.task = res.data
  }
}
// 获取历史数据
let queryParams = reactive({
  pageIndex: 1,
  pageSize: 6
})
let taskList = reactive({
  list: []
})
let totalNum = ref(0)
const getList = async (page) => {
  if (!page) {
    page = 1
  }
  queryParams.pageIndex = page
  const res = await postTaskList(queryParams)
  if (res && res.code === 0) {
    const {list, total} = res.data
    taskList.list = list
    totalNum.value = total
    // queryParams.pageIndex = 1
  }
}
// 登录
const loadingIcon = '<svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728=""><path fill="#999" d="M512 64a32 32 0 0 1 32 32v192a32 32 0 0 1-64 0V96a32 32 0 0 1 32-32zm0 640a32 32 0 0 1 32 32v192a32 32 0 1 1-64 0V736a32 32 0 0 1 32-32zm448-192a32 32 0 0 1-32 32H736a32 32 0 1 1 0-64h192a32 32 0 0 1 32 32zm-640 0a32 32 0 0 1-32 32H96a32 32 0 0 1 0-64h192a32 32 0 0 1 32 32zM195.2 195.2a32 32 0 0 1 45.248 0L376.32 331.008a32 32 0 0 1-45.248 45.248L195.2 240.448a32 32 0 0 1 0-45.248zm452.544 452.544a32 32 0 0 1 45.248 0L828.8 783.552a32 32 0 0 1-45.248 45.248L647.744 692.992a32 32 0 0 1 0-45.248zM828.8 195.264a32 32 0 0 1 0 45.184L692.992 376.32a32 32 0 0 1-45.248-45.248l135.808-135.808a32 32 0 0 1 45.248 0zm-452.544 452.48a32 32 0 0 1 0 45.248L240.448 828.8a32 32 0 0 1-45.248-45.248l135.808-135.808a32 32 0 0 1 45.248 0z"></path></svg>'
let loginLoading = ref(true)
const login = async () => {
  loginLoading.value = true
  const {timestamp, uid, md5} = router.query
  sessionStorage.setItem('md5', md5)
  try {
    const res = await postLogin({timestamp: Number(timestamp), uid}, {md5})
    if (res && res.code === 0) {
      console.log(res)
      sessionStorage.setItem('token', res.data.token)
      // store.commit('SET_TOKEN', res.data.token)
      // setToken(res.data.token)
      loginLoading.value = false
      getList()
      console.log(dispatch)
      dispatch('user/getInfo', {})
    } else {
      // removeToken()
      loginLoading.value = false
    }
  } catch (err) {
    // removeToken()
    loginLoading.value = false
  }
}

onMounted(() => {
  sessionStorage.removeItem('token')
  login()
})

</script>
<style lang="scss" scoped>
.main {
  width: 100%;
  background-color: rgba(255, 255, 255);
  height: 100%;
}

.siderbar {
  width: 78px;
  height: 100%;
  background-color: #222225;
  box-sizing: border-box;

  .nav-item {
    width: 52px;
    height: 51px;
    border-radius: 6px;
    margin: 30px auto 0;
  }

  .activeBg {
    background-color: #0234C2;
  }

  .icon {
    width: 36px;
    height: 32px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .icon1 {
    background-image: url(../assets/images/hui.png);
  }

  .icon2 {
    background-image: url(../assets/images/shou.png)
  }
}

.content {
  width: 45%;
  height: 100%;
  box-sizing: border-box;
  border-right: 1px solid var(--page-border-color);
}

.right-content {
  box-sizing: border-box;
}
</style>
