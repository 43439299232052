<template>
  <div class="operation-main center-center">
    <div class="center-box">
      <div class="oper-item" @click="onOperation('refresh')">
        <div class="icon icon1"></div>
        <div class="oper-text">刷新</div>
      </div>
      <!-- <div class="oper-item" @click="onOperation('download')">
        <div class="icon icon2"></div>
        <div class="oper-text">下载</div>
      </div>
      <div class="oper-item" @click="onOperation('view')">
        <div class="icon icon3"></div>
        <div class="oper-text">清晰化</div>
      </div>-->
       <div class="oper-item" @click="onOperation('collect')">
         <div class="icon icon4"></div>
         <div class="oper-text">加入作品集</div>
       </div>
     </div>
  </div>
</template>
<script setup>
import {ref} from 'vue'

const emit = defineEmits(['viewBigImg'])
const onOperation = (typeName) => {
  emit('onOpertion', {type: typeName})
}
</script>
<style lang="scss" scoped>
.operation-main {
  width: 100%;
  box-sizing: border-box;
  padding-top: 36px;
}

.oper-item {
  margin-bottom: 24px;
  cursor: pointer;
  text-align: center;

  .icon {
    width: 30px;
    height: 30px;
    margin: 0 auto;
  }

  .icon1 {
    background: url(../assets/images/re.png) no-repeat;
    background-size: 100%;
  }

  .icon2 {
    background: url(../assets/images/download2x.png) no-repeat;
    background-size: 100%;
  }

  .icon3 {
    background: url(../assets/images/image2x.png) no-repeat;
    background-size: 100%;
  }

  .icon4 {
    background: url(../assets/images/folder-open2x.png) no-repeat;
    background-size: 100%;
  }

  .oper-text {
    font-size: 14px;
    color: var(--page-textsub-color);
    margin-top: 12px;
  }
}
</style>
