import { POST_JSON } from '../../utils/http'

export const postCreatTask = (d) => POST_JSON('/poc/task/create', d)
export const postTaskDetail = (d) => POST_JSON('/poc/task/detail', d)
export const postRemoveTask = (d) => POST_JSON('/poc/task/remove', d)
export const postCollectTask = (d) => POST_JSON('/poc/task/edit', d)
export const postTaskList = (d) => POST_JSON('/poc/task/list', d)

export const postLogin = (d, b) => POST_JSON('/poc/user/login', d, b)
export const postInfo = (d, b) => POST_JSON('/poc/user/info', d, b)
