import { createApp } from 'vue'
import App from './App.vue'
import mitt from "mitt"
import ElementPlus from 'element-plus'
import { ElMessage } from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import '@/styles/theme.scss'
import '@/styles/index.scss'
import router from './router'
import store from './store'
import { installStore } from '../src/store'
import { installRouter } from '../src/router'
const app = createApp(App)
installStore(app)
installRouter(app)
app.config.globalProperties.$store = store;
app.config.globalProperties.$message = ElMessage;
app.config.globalProperties.$router = router;
app.config.globalProperties.$mybus = mitt()
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
	app.component(key, component)
}
app.use(ElementPlus).use(store).use(router)
app.mount('#app')